<template>
	<router-view />
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, onUnmounted, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { initializeComponents } from "@/core/plugins/keenthemes";
import Swal from "sweetalert2";
import * as generalMethods from "@/core/mixins/general_methods";

export default defineComponent({
	name: "app",

	setup() {
		const i18n = useI18n();

		i18n.locale.value = localStorage.getItem("lang") ? (localStorage.getItem("lang") as string) : "en";

		const internetConnection = reactive({
			connected: true as boolean,

			updateInternetConnection: () => {
				if (!navigator.onLine && internetConnection.connected) {
					generalMethods.showInternetDisconnectLoading();
				} else if (navigator.onLine && !internetConnection.connected) {
					generalMethods.closeSwal();
				}

				internetConnection.connected = navigator.onLine;
			},
		});

		onMounted(() => {
			nextTick(() => {
				initializeComponents();
			});

			window.addEventListener("online", internetConnection.updateInternetConnection);
			window.addEventListener("offline", internetConnection.updateInternetConnection);
		});

		onUnmounted(() => {
			window.removeEventListener("online", internetConnection.updateInternetConnection);
			window.removeEventListener("offline", internetConnection.updateInternetConnection);
		});

		(window as any).goToCustomerProfile = (customerId) => {
			generalMethods.goTo({ name: "merchant-customers-customer", params: { id: customerId } });
			Swal.close();
		};

		(window as any).goToBillingForCustomer = (customerId) => {
			generalMethods.goTo({ name: "merchant-billing", query: { customer_id: customerId } });
			Swal.close();
		};

		(window as any).closeSwal = () => {
			Swal.close();
		};

		return {
			generalMethods,
		};
	},
});
</script>

<style lang="scss">
@import "~animate.css/animate.min.css";
@import "~sweetalert2/dist/sweetalert2.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/common";
</style>
