import objectPath from "object-path";
import layoutConfig from "@/core/config/DefaultLayoutConfig";
import { Module, VuexModule } from "vuex-module-decorators";
import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";

interface StoreInfo {
	config: LayoutConfigTypes;
	initial: LayoutConfigTypes;
}

@Module
export default class ConfigModule extends VuexModule implements StoreInfo {
	config = layoutConfig;
	initial = layoutConfig;

	/**
	 * Get config from layout config
	 * @returns {function(path, defaultValue): *}
	 */
	get layoutConfig() {
		return (path, defaultValue) => {
			return objectPath.get(this.config, path, defaultValue);
		};
	}
}
