export class CommonData {
	static data = {};

	public static getData(key) {
		if (this.data[key] != undefined && this.data[key] != null) {
			return this.data[key];
		}
		return null;
	}

	public static addData(key, value) {
		this.data[key] = value;
	}

	public static removeData(key) {
		delete this.data[key];
	}
}
