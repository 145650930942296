<template>
	<el-switch
		:active-value="activeValue"
		:inactive-value="inactiveValue"
		:width="generalMethods.isMobileScreen() ? 60 : 80"
		:active-text="generalMethods.translate(activeText)"
		:inactive-text="generalMethods.translate(inactiveText)"
		:custom-active-text="generalMethods.translate(activeText)"
		:custom-inactive-text="generalMethods.translate(inactiveText)"
		:inline-prompt="inlinePrompt"
		:class="sClass"
		v-bind="$attrs"
	/>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import * as generalMethods from "@/core/mixins/general_methods";

export default defineComponent({
	name: "umt-element-plus-input",
	props: {
		activeValue: { default: 1 },
		inactiveValue: { default: 0 },
		activeText: { type: String, default: "active" },
		inactiveText: { type: String, default: "disabled" },
		inlinePrompt: { type: Boolean, default: true },
		sClass: { type: String, default: "el-switch-gray-off" },
	},
	setup() {
		return {
			generalMethods,
		};
	},
});
</script>
