<template>
	<router-link :to="localizedTo" v-bind="$attrs">
		<slot></slot>
	</router-link>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

import * as generalMethods from "@/core/mixins/general_methods";

export default defineComponent({
	name: "language-router-link",
	props: {
		to: {
			type: [String, Object],
		},
	},
	setup(props) {
		const localizedTo = computed(() => {
			const route = useRoute();

			let newTo = null as any;
			if (typeof props.to == "string") {
				newTo = (generalMethods.valueExist(route.params.language, true) ? "/" + route.params.language : "") + props.to;
			} else if (typeof props.to == "object") {
				newTo = {
					...props.to,
					params: {
						...props.to.params,
						language: route.params.language,
					},
				};
			} else {
				newTo = props.to;
			}

			return newTo;
		});

		return {
			localizedTo,
		};
	},
});
</script>
