enum Actions {
	// action types
	ADD_BODY_CLASSNAME = "addBodyClassName",
	REMOVE_BODY_CLASSNAME = "removeBodyClassName",
	ADD_BODY_ATTRIBUTE = "addBodyAttribute",
	REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
	ADD_CLASSNAME = "addClassName",
	LOGIN = "login",
	LOGOUT = "logout",
	REGISTER = "register",
	UPDATE_USER = "updateUser",
	FORGOT_PASSWORD = "forgotPassword",
	SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
	SET_TOOLBAR_BUTTONS_ACTION = "setToolBarButtonsAction",
	GET_GLOBAL_INFORMATIONS = "getGlobalInformations",
	SET_ACTIVE_WEBSITE_PAGE_ACTION = "setActiveWebSitePageAction",
	SET_WEBSITE_BREADCRUMB_ACTION = "setWebsiteBreadcrumbAction",
	SET_ACTIVE_ONLINE_PAGE_ACTION = "setActiveOnlinePageAction",
	SET_NOTIFICATIONS_MENU_ACTION = "setNotificationsMenuAction",
}

enum Mutations {
	// mutation types
	SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
	PURGE_AUTH = "logOut",
	SET_AUTH = "setAuth",
	SET_USER = "setUser",
	SET_PASSWORD = "setPassword",
	SET_ERROR = "setError",
	SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
	SET_TOOLBAR_BUTTONS_MUTATION = "setToolBarButtonsMutation",
	SET_GLOBAL_INFORMATIONS = "setGlobalInformations",
	SET_ACTIVE_WEBSITE_PAGE_MUTATION = "setActiveWebsitePageMutation",
	SET_WEBSITE_BREADCRUMB_MUTATION = "setWebsiteBreadcrumbMutation",
	SET_ACTIVE_ONLINE_PAGE_MUTATION = "setActiveOnlinePageMutation",
	SET_NOTIFICATIONS_MENU_MUTATION = "setNotificationsMenuMutation",
}

export { Actions, Mutations };
