<template>
	<el-form-item
		v-if="inForm"
		:label="hideLabel ? null : generalMethods.translate(label)"
		:for="id == null ? prop : id"
		:prop="prop"
		:rules="rules"
		:class="`${elFormItemClassses}`"
		label-width="100px"
	>
		<el-select
			:id="id == null ? prop : id"
			v-model="localModelValue"
			:value-key="valueKey"
			@change="(value) => emitEvent('change', value)"
			:disabled="disabled"
			:readonly="readonly"
			:placeholder="generalMethods.translate(placeholder)"
			:size="size"
			:teleported="false"
			:class="elClasses"
			:clearable="clearable"
		>
			<el-option
				v-for="(option, index) in options"
				:key="index"
				:label="
					optionEnglishArabicName
						? generalMethods.getValueByCurrentLanguage(
								option[(optionLabelKey == '' ? '' : optionLabelKey + '_') + 'english_name'],
								option[(optionLabelKey == '' ? '' : optionLabelKey + '_') + 'arabic_name']
							)
						: generalMethods.translate(option[optionLabelKey == '' ? 'name' : optionLabelKey])
				"
				:value="option"
			/>
		</el-select>
	</el-form-item>
	<el-select
		v-else
		:id="id == null ? prop : id"
		v-model="localModelValue"
		:value-key="valueKey"
		@change="(value) => emitEvent('change', value)"
		:disabled="disabled"
		:readonly="readonly"
		:placeholder="generalMethods.translate(placeholder)"
		:size="size"
		:teleported="false"
		:class="elClasses"
		:clearable="clearable"
	>
		<el-option
			v-for="(option, index) in options"
			:key="index"
			:label="
				optionEnglishArabicName
					? generalMethods.getValueByCurrentLanguage(
							option[(optionLabelKey == '' ? '' : optionLabelKey + '_') + 'english_name'],
							option[(optionLabelKey == '' ? '' : optionLabelKey + '_') + 'arabic_name']
						)
					: generalMethods.translate(option[optionLabelKey == '' ? 'name' : optionLabelKey])
			"
			:value="option"
		/>
	</el-select>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue";

import * as generalMethods from "@/core/mixins/general_methods";
import * as customValidation from "@/core/mixins/custom_validation";

export default defineComponent({
	name: "c-input",
	props: {
		id: { type: String, default: null },
		label: { type: String, default: null },
		prop: { type: String, default: null },
		valueKey: { type: String, default: "id" },
		options: { default: [] },
		optionEnglishArabicName: { type: Boolean, default: false },
		optionLabelKey: { type: String, default: "" },
		modelValue: {},
		required: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		readonly: { type: Boolean, default: false },
		extraRules: { type: Array, default: null },
		placeholder: { type: String, default: "select" },
		hideLabel: { type: Boolean, default: false },
		size: { type: String, default: "default" },
		elClasses: { type: String, default: "w-100" },
		elFormItemClassses: { type: String, default: "" },
		clearable: { type: Boolean, default: false },
		inForm: { type: Boolean, default: true },
	},
	setup(props, { emit }) {
		const localModelValue = computed({
			get() {
				return props.modelValue;
			},
			set(newValue) {
				emit("update:modelValue", newValue);
			},
		});

		const rules = computed(() => {
			let rules = [] as Array<any>;

			if (props.required) {
				rules.push({ required: true, message: generalMethods.getRequiredMessage(props.label) });
				rules.push({
					validator: (rule, value, callaback) => {
						customValidation.required(rule, value, callaback, props.label);
					},
				});
			}

			if (props.extraRules != null) {
				rules = rules.concat(props.extraRules);
			}

			return rules;
		});

		const emitEvent = (event, value) => {
			emit(event, value);
		};

		return {
			generalMethods,
			customValidation,

			localModelValue,

			rules,

			emitEvent,
		};
	},
});
</script>
