import { App } from "vue";

import {
	ElForm,
	ElFormItem,
	ElInput,
	ElRate,
	ElDatePicker,
	ElSwitch,
	ElSelect,
	ElOption,
	ElImage,
	ElCheckbox,
	ElDivider,
	ElPagination,
	ElRadioGroup,
	ElRadio,
	ElRadioButton,
	ElUpload,
	ElTooltip,
	ElCarousel,
	ElCarouselItem,
} from "element-plus";

import elInput from "@/components/element-plus/Input.vue";
import mobileInput from "@/components/element-plus/MobileInput.vue";
import Select from "@/components/element-plus/Select.vue";
import Switch from "@/components/element-plus/Switch.vue";
import UploadImage from "@/components/element-plus/UploadImage.vue";
import UploadImages from "@/components/element-plus/UploadImages.vue";

export function initElementPlus(app: App<Element>) {
	const size = "large";
	app.config.globalProperties.$ELEMENT = { size: size };

	app.component("el-form", ElForm);
	app.component("el-form-item", ElFormItem);
	app.component("el-input", ElInput);
	app.component("el-rate", ElRate);
	app.component("el-date-picker", ElDatePicker);
	app.component("el-switch", ElSwitch);
	app.component("el-select", ElSelect);
	app.component("el-option", ElOption);
	app.component("el-image", ElImage);
	app.component("el-checkbox", ElCheckbox);
	app.component("el-radio-group", ElRadioGroup);
	app.component("el-radio", ElRadio);
	app.component("el-radio-button", ElRadioButton);
	app.component("el-pagination", ElPagination);
	app.component("el-divider", ElDivider);
	app.component("el-upload", ElUpload);
	app.component("el-tooltip", ElTooltip);
	app.component("el-carousel", ElCarousel);
	app.component("el-carousel-item", ElCarouselItem);

	app.component("s-input", elInput);
	app.component("s-mobile-input", mobileInput);
	app.component("s-select", Select);
	app.component("s-switch", Switch);
	app.component("s-upload-image", UploadImage);
	app.component("s-upload-images", UploadImages);
	app.component("s-date", Date);
}
