<template>
	<el-form-item :prop="prop" :rules="rules" :class="`${elFormItemClassses}`">
		<div class="w-100">
			<el-upload
				class="draggabe-uploader draggabe-uploader-images"
				v-model:file-list="localModelValue"
				drag
				action=""
				multiple
				:auto-upload="false"
				list-type="picture-card"
				:on-preview="previewImage"
				accept=".jpg,.jpeg,.png"
			>
				<i class="fa-solid fa-cloud-arrow-up" style="font-size: 26px" />
				<template #file="{ file }">
					<img
						v-if="generalMethods.valueExist(file[existingKey])"
						class="el-upload-list__item-thumbnail"
						:src="generalMethods.getServerMediaPath(file[existingKey])"
						alt=""
					/>
					<img v-else class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
					<span class="el-upload-list__item-actions">
						<span class="el-upload-list__item-preview" @click="previewImage(file)">
							<i class="fa-solid fa-magnifying-glass-plus text-white"></i>
						</span>
						<span v-if="!disabled" class="el-upload-list__item-delete" @click="removeImage(file)">
							<i class="fa-solid fa-trash text-white"></i>
						</span>
					</span>
				</template>
			</el-upload>
		</div>
	</el-form-item>
	<div
		:ref="
			(el) => {
				previewImageModal.ref = el;
			}
		"
		class="modal fade"
		tabindex="-1"
		aria-hidden="true"
		style="background-color: rgb(0, 0, 0, 0.5)"
	>
		<div class="modal-dialog modal-dialog-centered mw-650px">
			<div class="modal-content">
				<img :src="previewImageModal.url" class="w-100" />
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, reactive } from "vue";

import { Modal } from "bootstrap";

import * as generalMethods from "@/core/mixins/general_methods";

export default defineComponent({
	name: "umt-element-plus-upload",
	props: {
		modelValue: {},
		existingKey: { type: String, default: null },
		label: { type: String, default: null },
		extraRules: { type: Array, default: null },
		prop: { type: String, default: null },
		required: { type: Boolean, default: false },
		elFormItemClassses: { type: String, default: "" },
	},
	emits: ["update:modelValue"],
	setup(props, { emit }) {
		onMounted(() => {
			previewImageModal.instance = new Modal(previewImageModal.ref);
		});

		const localModelValue = computed({
			get() {
				return props.modelValue;
			},
			set(newValue) {
				emit("update:modelValue", newValue);
			},
		}) as any;

		const rules = computed(() => {
			let rules = [] as Array<any>;

			if (props.required) {
				rules.push({ required: true, message: generalMethods.getRequiredMessage(props.label) });
			}

			if (props.extraRules != null) {
				rules = rules.concat(props.extraRules);
			}

			return rules;
		});

		const removeImage = (file) => {
			for (let i = 0; i < localModelValue.value.length; i++) {
				if (localModelValue.value[i].uid == file.uid) {
					localModelValue.value.splice(i, 1);
				}
			}
		};

		const previewImageModal = reactive({
			ref: null as any,
			instance: null as any,

			url: null as string | null,
		});

		const previewImage = (file) => {
			if (generalMethods.valueExist(file[props.existingKey])) {
				previewImageModal.url = generalMethods.getServerMediaPath(file[props.existingKey]);
			} else {
				previewImageModal.url = file.url;
			}

			previewImageModal.instance.show();
		};

		return {
			generalMethods,

			rules,

			localModelValue,

			removeImage,

			previewImageModal,
			previewImage,
		};
	},
});
</script>
