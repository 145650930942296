import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface ToolbarButton {
	title: string;
	action: string;
}

interface StoreInfo {
	toolBarButtons: Array<ToolbarButton>;
	activeWebsitePage: string | null;
	websiteBreadcrumb: Array<string> | null;
}

@Module
export default class PagesCustomsModule extends VuexModule implements StoreInfo {
	toolBarButtons = Array<ToolbarButton>();
	activeWebsitePage = null as string | null;
	websiteBreadcrumb = null as Array<string> | null;
	activeOnlinePage = null as string | null;
	notifications = [] as any;

	get toolbarButtons(): Array<ToolbarButton> {
		return this.toolBarButtons;
	}

	@Mutation
	[Mutations.SET_TOOLBAR_BUTTONS_MUTATION](inputButtons: Array<ToolbarButton>): void {
		this.toolBarButtons = inputButtons;
	}

	@Action
	[Actions.SET_TOOLBAR_BUTTONS_ACTION](inputButtons) {
		this.context.commit(Mutations.SET_TOOLBAR_BUTTONS_MUTATION, inputButtons);
	}

	get getActiveWebsitePage(): string | null {
		return this.activeWebsitePage;
	}

	@Mutation
	[Mutations.SET_ACTIVE_WEBSITE_PAGE_MUTATION](page: string | null): void {
		this.activeWebsitePage = page;
	}

	@Action
	[Actions.SET_ACTIVE_WEBSITE_PAGE_ACTION](page) {
		this.context.commit(Mutations.SET_ACTIVE_WEBSITE_PAGE_MUTATION, page);
	}

	get getWebsiteBreadcrumb(): Array<string> | null {
		return this.websiteBreadcrumb;
	}

	@Mutation
	[Mutations.SET_WEBSITE_BREADCRUMB_MUTATION](pages: Array<string> | null): void {
		this.websiteBreadcrumb = pages;
	}

	@Action
	[Actions.SET_WEBSITE_BREADCRUMB_ACTION](pages) {
		this.context.commit(Mutations.SET_WEBSITE_BREADCRUMB_MUTATION, pages);
	}

	get getActiveOnlinePage(): string | null {
		return this.activeOnlinePage;
	}

	@Mutation
	[Mutations.SET_ACTIVE_ONLINE_PAGE_MUTATION](page: string | null): void {
		this.activeOnlinePage = page;
	}

	@Action
	[Actions.SET_ACTIVE_ONLINE_PAGE_ACTION](page) {
		this.context.commit(Mutations.SET_ACTIVE_ONLINE_PAGE_MUTATION, page);
	}

	get getNotificationsMenu(): any {
		return this.notifications;
	}

	@Mutation
	[Mutations.SET_NOTIFICATIONS_MENU_MUTATION](notifications: any): void {
		const parentNotifications = this.notifications;

		Object.keys(notifications).forEach(function (key) {
			parentNotifications[key] = notifications[key];
		});
	}

	@Action
	[Actions.SET_NOTIFICATIONS_MENU_ACTION](notifications: any) {
		this.context.commit(Mutations.SET_NOTIFICATIONS_MENU_MUTATION, notifications);
	}
}
