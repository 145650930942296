import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export const setToolBarButtons = (buttons: Array<object>): void => {
	store.dispatch(Actions.SET_TOOLBAR_BUTTONS_ACTION, buttons);
};

export const setActiveWebSitePage = (page: string): void => {
	store.dispatch(Actions.SET_ACTIVE_WEBSITE_PAGE_ACTION, page);
};

export const setWebsiteBreadcrumb = (pages: Array<string> | null): void => {
	store.dispatch(Actions.SET_WEBSITE_BREADCRUMB_ACTION, pages);
};

export const setActiveOnlinePage = (page: string): void => {
	store.dispatch(Actions.SET_ACTIVE_ONLINE_PAGE_ACTION, page);
};

export const setNotificationsMenu = (notifications: any): void => {
	store.dispatch(Actions.SET_NOTIFICATIONS_MENU_ACTION, notifications);
};
