<template>
	<el-form-item :label="generalMethods.translate(label)" :prop="prop" :rules="rules" :class="`${elFormItemClassses}`">
		<div class="d-flex flex-column">
			<el-upload
				:class="`image-uploader w-${imageSize} h-${imageSize}`"
				action=""
				:before-upload="upload"
				:limit="1"
				:show-file-list="false"
				accept=".jpg,.png,.jpeg"
			>
				<div :class="`d-flex justify-content-center align-items-center w-${imageSize} h-${imageSize}`">
					<img v-if="uploadedImage" :src="uploadedImage" :class="`image max-w-${imageSize} max-h-${imageSize} p-5`" />
					<img v-else-if="image" :src="generalMethods.getServerMediaPath(image)" :class="`image max-w-${imageSize} max-h-${imageSize} p-5`" />
					<i v-else class="fa-solid fa-plus" style="font-size: 28px; color: #8c939d"></i>
				</div>
			</el-upload>
			<div v-if="generalMethods.valueExist(image) || generalMethods.valueExist(uploadedImage)" class="d-flex justify-content-center mt-5">
				<i class="fa-solid fa-trash text-danger cursor-pointer" @click="upload(null)" />
			</div>
		</div>
	</el-form-item>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from "vue";

import * as generalMethods from "@/core/mixins/general_methods";

export default defineComponent({
	name: "umt-element-plus-upload",
	props: {
		image: { type: String, default: null },
		label: { type: String, default: null },
		extraRules: { type: Array, default: null },
		prop: { type: String, default: null },
		required: { type: Boolean, default: false },
		elFormItemClassses: { type: String, default: "" },
		imageSize: { type: String, default: "178px" },
	},
	emits: ["fileUploaded"],
	setup(props, { emit }) {
		const uploadedImage = ref(null as null | string);

		const rules = computed(() => {
			let rules = [] as Array<any>;

			if (!generalMethods.valueExist(props.image) && props.required) {
				rules.push({ required: true, message: generalMethods.getRequiredMessage(props.label) });
			}

			if (props.extraRules != null) {
				rules = rules.concat(props.extraRules);
			}

			return rules;
		});

		const upload = (file) => {
			if (generalMethods.valueExist(file)) {
				uploadedImage.value = URL.createObjectURL(file);
			} else {
				uploadedImage.value = null;
			}

			emit("fileUploaded", file);

			return false;
		};

		return {
			generalMethods,

			uploadedImage,
			rules,
			upload,
		};
	},
});
</script>
