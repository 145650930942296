export function initLanguage() {
	const currentLocation = window.location.pathname;

	if (currentLocation == "/en" || currentLocation == "/en/" || currentLocation.startsWith("/en/") || currentLocation.startsWith("en/")) {
		localStorage.setItem("lang", "en");

		if (process.env.VUE_APP_ENVIRONMENT == "local") {
			document.getElementsByTagName("html")[0].setAttribute("direction", "ltr");
			document.getElementsByTagName("html")[0].style.direction = "ltr";
			document.getElementsByTagName("html")[0].setAttribute("lang", "en");
			document.querySelector("link[language-style]")?.setAttribute("href", "/css/style." + process.env.PACKAGE_VERSION + ".css");
		}
	} else {
		localStorage.setItem("lang", "ar");

		if (process.env.VUE_APP_ENVIRONMENT == "local") {
			document.getElementsByTagName("html")[0].setAttribute("direction", "rtl");
			document.getElementsByTagName("html")[0].style.direction = "rtl";
			document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
			document.querySelector("link[language-style]")?.setAttribute("href", "/css/style.rtl." + process.env.PACKAGE_VERSION + ".css");
		}
	}
}
