import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "fa-solid fa-plus",
  style: {"font-size":"28px","color":"#8c939d"}
}
const _hoisted_5 = {
  key: 0,
  class: "d-flex justify-content-center mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    label: _ctx.generalMethods.translate(_ctx.label),
    prop: _ctx.prop,
    rules: _ctx.rules,
    class: _normalizeClass(`${_ctx.elFormItemClassses}`)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_upload, {
          class: _normalizeClass(`image-uploader w-${_ctx.imageSize} h-${_ctx.imageSize}`),
          action: "",
          "before-upload": _ctx.upload,
          limit: 1,
          "show-file-list": false,
          accept: ".jpg,.png,.jpeg"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(`d-flex justify-content-center align-items-center w-${_ctx.imageSize} h-${_ctx.imageSize}`)
            }, [
              (_ctx.uploadedImage)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.uploadedImage,
                    class: _normalizeClass(`image max-w-${_ctx.imageSize} max-h-${_ctx.imageSize} p-5`)
                  }, null, 10, _hoisted_2))
                : (_ctx.image)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: _ctx.generalMethods.getServerMediaPath(_ctx.image),
                      class: _normalizeClass(`image max-w-${_ctx.imageSize} max-h-${_ctx.imageSize} p-5`)
                    }, null, 10, _hoisted_3))
                  : (_openBlock(), _createElementBlock("i", _hoisted_4))
            ], 2)
          ]),
          _: 1
        }, 8, ["class", "before-upload"]),
        (_ctx.generalMethods.valueExist(_ctx.image) || _ctx.generalMethods.valueExist(_ctx.uploadedImage))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("i", {
                class: "fa-solid fa-trash text-danger cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.upload(null)))
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["label", "prop", "rules", "class"]))
}