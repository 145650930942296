import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createBlock(_component_el_switch, _mergeProps({
    "active-value": _ctx.activeValue,
    "inactive-value": _ctx.inactiveValue,
    width: _ctx.generalMethods.isMobileScreen() ? 60 : 80,
    "active-text": _ctx.generalMethods.translate(_ctx.activeText),
    "inactive-text": _ctx.generalMethods.translate(_ctx.inactiveText),
    "custom-active-text": _ctx.generalMethods.translate(_ctx.activeText),
    "custom-inactive-text": _ctx.generalMethods.translate(_ctx.inactiveText),
    "inline-prompt": _ctx.inlinePrompt,
    class: _ctx.sClass
  }, _ctx.$attrs), null, 16, ["active-value", "inactive-value", "width", "active-text", "inactive-text", "custom-active-text", "custom-inactive-text", "inline-prompt", "class"]))
}