import { RouteRecordRaw } from "vue-router";

export const websiteRoutes: Array<RouteRecordRaw> = [
	{
		path: "/:language(en|ar|)?/",
		component: () => import("@/website/layout/Layout.vue"),
		children: [
			{
				path: "",
				name: "website-landing",
				component: () => import("@/website/views/Landing.vue"),
			},
			{
				path: "terms-and-conditions",
				name: "website-terms-and-conditions",
				component: () => import("@/website/views/TermsAndConditions.vue"),
			},
			{
				path: "privacy-policy",
				name: "website-privacy-policy",
				component: () => import("@/website/views/PrivacyPolicy.vue"),
			},
			{
				path: "business/features",
				name: "website-business-features",
				component: () => import("@/website/views/business/Features.vue"),
			},
			{
				path: "features",
				name: "website-features",
				component: () => import("@/website/views/business/Features.vue"),
			},
			{
				path: "registration",
				name: "website-registration",
				component: () => import("@/website/views/registration/Registration.vue"),
			},
			{
				path: "about-company",
				name: "website-about-company",
				component: () => import("@/website/views/AboutCompany.vue"),
			},
			{
				path: "business/beauty-center-software",
				name: "website-business-programs-salons-management-system",
				component: () => import("@/website/views/business/SalonsManagementSystem.vue"),
			},
			{
				path: "beauty-center-software",
				name: "website-programs-salons-management-system",
				component: () => import("@/website/views/business/SalonsManagementSystem.vue"),
			},
			{
				path: "contact-us",
				name: "website-contact-us",
				component: () => import("@/website/views/contact-us/ContactUs.vue"),
			},
			{
				path: "search",
				name: "website-search",
				component: () => import("@/website/views/Search.vue"),
			},
			{
				path: "property/:uniqueId",
				name: "website-property",
				component: () => import("@/website/views/Property.vue"),
			},
		],
	},
	{
		path: "/:language(en|ar|)?/contact-us-success",
		name: "website-contact-us-success",
		component: () => import("@/website/views/contact-us/ContactUsSuccess.vue"),
	},
];
