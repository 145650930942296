export class JsonData {
	private data;

	constructor() {
		this.data = {};
	}

	append(key, value) {
		if (value !== null && value !== "") {
			if (typeof value === "string") {
				if (value.trim() !== "") {
					this.data[key] = value.trim();
				}
			} else {
				this.data[key] = value;
			}
		}
	}

	appendEvenIfEmpty(key, value) {
		if (value !== null) {
			this.data[key] = value;
		}
	}

	appendyNonEmptyCheckbox(key, value) {
		if (value !== null && value !== "") {
			this.data[key] = value ? 1 : 0;
		}
	}

	appendArray(key, value) {
		if (value != null && value != "") {
			this.data[key] = [];
			for (let i = 0; i < value.length; i++) {
				this.data[key].push(value[i]);
			}
		}
	}

	appendObject(key, value, valueKey = "id") {
		if (value != null && value != "") {
			this.data[key] = value[valueKey];
		}
	}

	appendArrayOfObjects(key, value, valueKey = "id") {
		if (value != null && value != "") {
			this.data[key] = [];
			for (let i = 0; i < value.length; i++) {
				this.data[key].push(value[i][valueKey]);
			}
		}
	}

	getData() {
		return this.data;
	}
}
