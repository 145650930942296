import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import PagesCustomsModule from "@/store/modules/PagesCustomsModule";

config.rawError = true;

const store = createStore({
	modules: {
		AuthModule,
		BodyModule,
		BreadcrumbsModule,
		ConfigModule,
		PagesCustomsModule,
	},
});

export default store;
